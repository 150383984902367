import React, { Fragment } from 'react';
import { Link } from "gatsby"
import { css } from "@emotion/core";
import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from "react-helmet";
import * as ROUTES from '../constants/routes';
import PasswordForgetForm from '../components/PasswordForget';

const PasswordForgetPage = () => (
  <Fragment>
    <h1>Forgot Password?</h1>
    <PasswordForgetForm />
    <Link to={ROUTES.SIGN_IN} className="signUpBACK"> Back</Link>
  </Fragment>
);

export default () => (
  <Layout>
    <SEO title="Compass Minerals SaltSmart" />
      <Helmet defer={true}>
        <body className="login" />
      </Helmet>
      <header className="mainHead flexContainer homeHead  faCenter fjCenter">      
        <div className="fCol12 menuCtrl">
          <div className="headerTitle">Home</div>        
        </div>
      </header>

      <section id="home-content" className="flexContainer secondaryTab">
      <div className="fCol12 maxTablet">
        <div className="flexContainer homeInner">
        <PasswordForgetPage />
        </div>
        <div className="compassLogo"/>
      </div>
    </section>    
  </Layout>  
);